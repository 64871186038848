<template>
    <b-overlay :show="showLoader">
        <div class="my-orders">
            <h1 class="text-secondary font" style="font-size: xx-large">Moja naročila</h1>

            <b-table class="table mt-2" responsive striped :items="items" :fields="fields">
                <template #cell(date_created)="row">
                    <span>{{dayjs(row.item.date_created).format('DD.MM.YYYY')}}</span>
                </template>
                <template #cell(total_price)="row">
                    <span>{{$convertPriceIntegerToFloat(calculatePrice(row.item))}} €</span>
                </template>
                <template #cell(payment_type)="row">
                    <span v-if="row.item.payment_type === 1">Plačilo prek spleta (Visa, Mastercard, American Express)</span>
                    <span v-else-if="row.item.payment_type === 2">Plačilo preko upravnika</span>
                    <span v-else-if="row.item.payment_type === 3">Plačilo preko upravnika na obroke</span>
                </template>
                <template #cell(status)="row">
                    <b-badge v-if="row.item.status[row.item.status.length - 1].status === 0" variant="secondary">Naročilo oddano</b-badge>
                    <b-badge v-else-if="row.item.status[row.item.status.length - 1].status === 1" variant="info">Naročilo potrjeno</b-badge>
                    <b-badge v-else-if="row.item.status[row.item.status.length - 1].status === 2" variant="danger">Naročilo zavrnjeno</b-badge>
                    <b-badge v-else-if="row.item.status[row.item.status.length - 1].status === 3" variant="warning">Čakam na plačilo</b-badge>
                    <b-badge v-else-if="row.item.status[row.item.status.length - 1].status === 4" variant="danger">Plačilo zavrnjeno</b-badge>
                    <b-badge v-else-if="row.item.status[row.item.status.length - 1].status === 5" variant="success">Plačilo potrjeno</b-badge>
                    <b-badge v-else-if="row.item.status[row.item.status.length - 1].status === 6" variant="info">Naročilo v obdelavi</b-badge>
                    <b-badge v-else-if="row.item.status[row.item.status.length - 1].status === 7" variant="success">Naročilo zaključeno</b-badge>
                </template>
                <template #cell(pdf)="row">
                    <a @click="downloadNarociloPDF(row.item,`Narocilo ${row.item.bbis_order_id}`)">
                        <img width="35px" height="35px" :src="require('@/assets/svg/pdf_icon.svg')"/>
                    </a>
                </template>
                <template #cell(actions)="row">
                    <a class="cursor-pointer text-secondary" @click="$router.push({name: 'my-order', params: {order_id: row.item.id}}); $scrollToTop()">Podrobnosti naročila</a>
                </template>
                <template v-if="items.length === 0" slot="bottom-row">
                    <b-td :colspan="fields.length">
                        <span class="d-flex justify-content-center">Ni podatkov za prikaz</span>
                    </b-td>
                </template>
            </b-table>
        </div>
        <div class="d-flex justify-content-end">
            <pagination v-if="pagination" v-model="pagination" class="mt-1"/>
        </div>
    </b-overlay>
</template>

<script>
    import {BOverlay, BTable, BBadge, BTd} from 'bootstrap-vue'
    import Pagination from '@/views/Components/Pagination.vue'

    export default {
        components: {BOverlay, BTable, BBadge, BTd, Pagination},
        data() {
            return {
                showLoader: false,
                fields: [
                    { key: 'bbis_order_id', label: 'Št. naročila', sortable: false, class: 'text-left' },
                    { key: 'date_created', label: 'Datum', sortable: false, class: 'text-left' },
                    { key: 'total_price', label: 'Znesek', sortable: false, class: 'text-left' },
                    { key: 'payment_type', label: 'Plačilo', sortable: false, class: 'text-left' },
                    { key: 'status', label: 'Status', sortable: false, class: 'text-left' },
                    { key: 'pdf', label: 'Račun', sortable: false, class: 'text-left' },
                    { key: 'actions', label: '', sortable: false, class: 'text-left' }
                ],
                items: [],
                pagination: null,
                created: false
            }
        },
        methods: {
            calculatePrice(item) {
                return item.total_price + item.shipping_price
            },
            getQueryParams() {

                const page = this.$route.query.page ?? 1
                const perPage = this.$route.query.perPage ?? 12
                this.perPage = perPage

                const pageQueryParam = (page ? `&page=${page}&` : '')
                const perPageQueryParam = (perPage ? `&perPage=${perPage}&` : '')

                const p = [
                    pageQueryParam,
                    perPageQueryParam
                ]

                return p.join('')
            },
            async loadData() {
                const thisIns = this
                thisIns.showLoader = true

                const queryParams = this.getQueryParams()

                await thisIns.$http.get(`/api/user/v1/web_shop/order/?${queryParams}`)
                    .then(res => {
                        this.items = res.data.items ?? []
                        this.pagination = res.data.pagination
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            downloadNarociloPDF(item, pdf_title) {
                const thisIns = this
                this.$http({
                    url: `/api/user/v1/web_shop/narocilo/${item.bbis_order_id}`, //your url
                    method: 'GET',
                    responseType: 'blob' // important
                })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', `${pdf_title}.pdf`) //or any other extension
                        document.body.appendChild(link)
                        link.click()
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju pdf je prišlo do napake!\n${error.message}`)
                    })
            }
        },
        async beforeMount() {
            await this.loadData()
            this.created = true
        },
        watch: {
            'pagination.current_page'() {
                if (!this.created || !this.pagination) return
                this.$router.push({'path': this.$route.fullPath, 'query': {'page': this.pagination.current_page, 'perPage': this.pagination.per_page = 12}})
                this.loadData()
            }
        }
    }
</script>

<style>
.my-orders .font {
  font-weight: bold;
}
.my-orders .table th {
  font-weight: normal;
  font-size: 16px !important;
}
.my-orders .table th:first-child {
  /*width: 150px;*/
  padding-right: 0 !important;
}
.my-orders .table td:nth-child(3) {
  min-width: 120px;
}
.my-orders .table tr {
  font-size: 15px;
  padding-left: 1.2rem !important;
  padding-right: 1.2rem !important;;
}
.my-orders .table th, .my-orders .table tr {
  background-color: #dbe9f5  !important;
  border-top: none;
  border-bottom: none;
  text-transform: none;
}
.my-orders .table tr {
  background-color: white !important;
  border-top: 1px solid black;
}
.my-orders .table tr td {
  border-top: none;
}
.my-orders .table tr:first-child {
  border-top: none;
}
.my-orders .table thead tr th {
  white-space: pre-wrap;
  text-align:left;
}
.my-orders .table tr td:first-child {
  font-weight: bold;
}
.my-orders .badge {
  border-radius: 0;
  padding: 0.6rem 0.8rem;
  width: 130px;
  font-size: 12px;
  font-weight: normal;
}
</style>